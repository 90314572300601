import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import Swal from 'sweetalert2';

const MpValidation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const search = useLocation().search;

  useEffect(() => {
    const status = pathname.split('/').pop(); // Obtiene 'success' o 'failure'
    // if (status === 'success' || status === 'failure' || status === 'pending') {
    if (status === 'success') {
        Swal.fire({
          title: "El pago fue exitoso",
          html:'<p>¿Crees que son muchos pasos para recargar saldo?</p><p>Pruebas las suscripciones de pago recurrente y olvidate de ingresar aca cada que se acaba el paquete</p>',
          confirmButtonText: "Ok",
          icon: "success",
         showDenyButton: true,
          denyButtonText: `Ir a suscripciones`
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/');
          }else if (result.isDenied) {
            navigate('/perfil?tab=suscripcion');
          }
        });
      navigate('/');
    } else if (status === 'pending') {
      Swal.fire({
        title: "El pago esta pendiente",
        html:'<p>¿Crees que son muchos pasos para recargar saldo?</p><p>Pruebas las suscripciones de pago recurrente y olvidate de ingresar aca cada que se acaba el paquete</p>',
        confirmButtonText: "Ok",
        icon: "success",
       showDenyButton: true,
        denyButtonText: `Ir a suscripciones`
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/');
        }else if (result.isDenied) {
          navigate('/perfil?tab=suscripcion');
        }
      });
      navigate('/');
    } else if (status === 'failure') {
      queryClient.invalidateQueries({ queryKey: ['gauge-data'] });
      Swal.fire({
        title: "Ocurrió un error con el pago",
        confirmButtonText: "Ok",
        icon: "error",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/');
        }
      });
      navigate('/');
    }
    else {
      navigate('/');
    }
  }, [pathname]);

  return (
    <div className="auth-page-wrapper pt-5 vh-100 d-flex align-items-center">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles" style={{height: '100%'}}>
        <div className="bg-overlay"></div>

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <Container>
        <Row className="justify-content-center">
          <Col xs={12} lg={6} xl={5}>
            <Card>
              <CardBody>
                <div className="text-center mt-2">
                  <h5 className="text-primary">Validando pago...</h5>
                  <Spinner className="text-primary" />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )

};

export default MpValidation;
